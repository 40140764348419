<template>
  <div id="text-page">
    <h3>Impressum</h3>
    <p class="lead"><span class="font-weight-bold">Angaben gemäß § 5 TMG</span><br />
    Chang Asia Shop<br />
    Ranu Hasuk<br />
    Inhaber: Ranu Hasuk<br /> 
    Bockrisweg 11<br />
    22761 Hamburg<br />
    </p>

    <p class="lead"><span class="font-weight-bold">Kontakt</span><br />
      Telefon: +49 170 8971613<br />
      E-Mail: info@chang-asia-shop.de<br />
      Website: www.chang-asia-shop.de
    </p>
    
    <p class="lead"><span class="font-weight-bold">Finanzamt Hamburg-Altona</span><br />
      <span class="font-weight-bold">Umsatzsteuer-Identifikationsnummer:</span> DE336329023</p>

      <p class="lead"><span class="font-weight-bold">Streitschlichtung</span><br />
      
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit. Die Plattform finden Sie unter <a href="https://ec.europa.eu/consumers/odr/">ec.europa.eu/consumers/odr/</a>. Wir sind weder bereit noch verpflichtet, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
  <br />
  </div>
</template>
<script>
export default {
  name: "ShopAboutImpressum",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>