<template>
    <div id="text-page">
        <h3>Zahlungsarten</h3>
        <p class="lead"><span class="font-weight-bold">1. Zahlungsm&#xf6;glichkeiten</span></p>
        <p class="lead"><span class="font-weight-bold">1.1 Lieferungen innerhalb Deutschlands</span></p>
        <p class="lead">F&#xfc;r Lieferungen innerhalb Deutschlands bieten wir folgende Zahlungsm&#xf6;glichkeiten
            an:<br>
            - Vorkasse - Zahlung per &#xdc;berweisung<br>
            - Vorkasse - Zahlung per PayPal<br>
            - Barzahlung bei Abholung</p>
        <p class="lead"><span class="font-weight-bold">1.2 Lieferungen ins Ausland</span></p>    
        <p class="lead">F&#xfc;r Lieferungen ins Ausland bieten wir folgende Zahlungsm&#xf6;glichkeiten an:<br>
            - Vorkasse - Zahlung per &#xdc;berweisung<br>
            - Vorkasse - Zahlung per PayPal</p>
        <p class="lead"><span class="font-weight-bold">2. Details zu den einzelnen Zahlungsm&#xf6;glichkeiten</span></p>
        <p class="lead"><span class="font-weight-bold">2.1 Vorkasse - Zahlung per &#xdc;berweisung</span></p>
        <p class="lead">Bei Vorkasse per Banküberweisung zahlen Sie den Rechnungsbetrag vorab auf unser Konto. Sobald
            das
            Geld unserem Konto gutgeschrieben wurde, versenden wir Ihre Ware umgehend. Die Zahlungsinformationen
            erhalten Sie
            zusammen mit der Auftragsbestätigung per E-Mail. Bitte überweisen Sie den vollständigen Rechnungsbetrag
            unter Angabe
            Ihrer Bestellnummer, damit wir die Überweisung Ihrer Bestellung zuordnen können.</p>
        <p class="lead"><span class="font-weight-bold">2.2 Vorkasse - Zahlung per PayPal</span></p>
        <p class="lead">Bei Vorkasse per PayPal zahlen Sie den Rechnungsbetrag vorab auf unser PayPal-Konto. Sobald das
            Geld dem PayPal-Konto gutgeschrieben wurde, versenden wir Ihre Ware umgehend. Die Zahlungsinformationen
            erhalten Sie
            zusammen mit der Auftragsbestätigung per E-Mail. Bitte zahlen Sie den vollständigen Rechnungsbetrag unter
            Angabe
            Ihrer Bestellnummer, damit wir die Zahlung Ihrer Bestellung zuordnen können.</p>
            <p class="lead"><span class="font-weight-bold">2.3 Barzahlung bei Abholung</span></p>
        <p class="lead">Barzahlung ist nur bei Abholung möglich.</p>
    </div>
</template>
<script>
export default {
    name: 'ShopAboutZahlungsarten'
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>