<template>
    <div id="text-page">
        <h3>Datenschutzerkl&#xe4;rung</h3>
        <p class="lead">Diese Datenschutzerkl&#xe4;rung kl&#xe4;rt Sie &#xfc;ber die Art, den Umfang und Zweck der
            Verarbeitung ihrer
            personenbezogenen Daten (nachfolgend kurz &#x201e;Daten&#x201c;) durch uns und Ihre diesbez&#xfc;glichen
            Rechte auf.</p>
        <p class="lead"><span class="font-weight-bold">1. Wer ist f&#xfc;r die Datenverarbeitung verantwortlich und an
                wen
                kann ich mich wenden</span></p>
        <p class="lead">Verantwortlicher f&#xfc;r die Verarbeitung von Daten auf unserer Website im Sinne der
            Datenschutz-Grundverordnung (DSGVO) ist:<br>
            <br>
            Ranu Hasuk<br>
            Bockrisweg 11<br>
            22761 Hamburg<br><br>
            Telefon: +49 (0) 170 93 93 28<br>
            E-Mail: <a
                href="mailto:datenschutz@chang-asia-shop.de?subject=Datenschutz-Mitteilung Chang-Asia-Shop&body=Ihre Mitteilung:">datenschutz@chang-asia-shop.de</a><br><br>
            Sofern Sie der Erhebung, Verarbeitung oder Nutzung Ihrer Daten gem&#xe4;&#xdf; den datenschutzrechtlichen
            Bestimmungen
            insgesamt oder f&#xfc;r einzelne Ma&#xdf;nahmen widersprechen m&#xf6;chten, richten Sie Ihren Widerspruch an
            die oben
            genannte Anschrift oder per E-Mail an die oben genannte E-Mail-Adresse.
        </p>
        <p class="lead"><span class="font-weight-bold">2. Auf welcher Rechtsgrundlage verarbeiten wir Ihre Daten</span>
        </p>
        <p class="lead">Zun&#xe4;chst teilen wir Ihnen gem&#xe4;&#xdf; Art. 13 DSGVO im Folgenden die Rechtsgrundlagen
            unserer
            Datenverarbeitungen mit. Wird die Rechtsgrundlage in dieser Datenschutzerkl&#xe4;rung nicht
            ausdr&#xfc;cklich genannt,
            so gilt das Folgende:<br>
            - Haben Sie zu der Verarbeitung Ihrer personenbezogenen Daten Ihre Einwilligung gegeben,
            ist die Rechtsgrundlage Art. 6 Abs. 1 lit. a und Art. 7 DSGVO.<br>- Erfolgt die Verarbeitung zur
            Erf&#xfc;llung
            unserer Leistungen und Durchf&#xfc;hrung vertraglicher Ma&#xdf;nahmen sowie Beantwortung von Anfragen ist
            die
            Rechtsgrundlage Art. 6 Abs. 1 lit. b DSGVO.<br>- Erfolgt die Verarbeitung zur Erf&#xfc;llung unserer
            rechtlichen
            Verpflichtungen ist die Rechtsgrundlage Art. 6 Abs. 1 lit. c DSGVO.<br>- Erfolgt die Verarbeitung zur
            Wahrung
            unserer berechtigten Interessen ist die Rechtsgrundlage Art. 6 Abs. 1 lit. f DSGVO.<br>- Ist eine
            Verarbeitung
            personenbezogener Daten erforderlich, um lebenswichtige Interessen der betroffenen Person oder einer anderen
            nat&#xfc;rlichen Person zu sch&#xfc;tzen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.<br><br>
            Erfolgt eine Verarbeitung auf Grundlage Ihrer Einwilligung, so k&#xf6;nnen Sie diese jederzeit mit Wirkung
            f&#xfc;r
            die Zukunft widerrufen. Ihren Widerruf k&#xf6;nnen Sie per Post an oben genannte Anschrift oder per E-Mail
            an die
            oben genannte E-Mail-Adresse &#xfc;bermitteln.<br><br>
            F&#xfc;r den Fall, dass wir im Rahmen unserer Verarbeitung Daten gegen&#xfc;ber anderen Personen und
            Unternehmen
            (Auftragsverarbeitern oder Dritten) offenbaren, Daten an diese &#xfc;bermitteln oder ihnen sonst Zugriff auf
            die
            Daten gew&#xe4;hren, erfolgt dies ausschlie&#xdf;lich auf Grundlage einer gesetzlichen Erlaubnis (z.B. wenn
            eine
            &#xdc;bermittlung
            der Daten an Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b DSGVO zur
            Vertragserf&#xfc;llung
            erforderlich ist), Sie eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf Grundlage
            unserer berechtigten Interessen (z.B. beim Einsatz von Beauftragten, Webhostern, etc. gem, Art 6 Abs. 1 lit.
            C DSGVO).<br><br>
            F&#xfc;r den Fall, dass wir Dritte mit der Verarbeitung von Daten beauftragen, geschieht dies auf Grundlage
            des
            Art. 28 DSGVO. (sog. &#x201e;Auftragsverarbeitung&#x201c;).
        </p>
        <p class="lead"><span class="font-weight-bold">3. Welche Daten werden verarbeitet</span>
        </p>
        <p class="lead"><u><span class="font-weight-bold">Bestellinformationen</span></u>
        </p>
        <p class="lead">
            Wenn Sie &#xfc;ber unsere Webseite eine Bestellung durchf&#xfc;hren, erheben wir Ihren Vor- und Nachnamen,
            Ihre
            Anschrift, Ihre E-Mail Adresse und optional Ihre Telefonnummer (Kontaktdaten). Dar&#xfc;ber hinaus erheben
            wir die Daten zu Ihrer Bestellung, also welche Produkte Sie zu welchem Preis bestellt haben (Bestelldaten).
            Diese Daten ben&#xf6;tigen wir, um das Vertragsverh&#xe4;ltnis zu erf&#xfc;llen, also Ihnen die bestellten
            Waren
            zuzusenden. Wenn Sie &#xfc;ber unsere Webseite eine Bestellung vornehmen, werden Ihre Kontakt- und
            Bestelldaten
            an unsere interne Bestellabteilung und Buchhaltung sowie an unseren Lieferanten
            weitergegeben.<br><br>
            Diese Verarbeitung erfolgt zur Erf&#xfc;llung unserer vertraglichen Verpflichtungen und Serviceleistungen
            gem.
            Art. 6 Abs. 1 lit b. DSGVO.<br><br>
            Sie haben die M&#xf6;glichkeit, ein Kundenkonto anlegen. Im Rahmen der Registrierung werden Ihnen die
            erforderlichen Pflichtangaben mitgeteilt. Die im Rahmen der Registrierung eingegebenen Daten werden f&#xfc;r
            die
            Zwecke der Nutzung des Angebotes verwendet. Wenn Nutzer ihr Nutzerkonto gek&#xfc;ndigt haben, werden deren
            Daten
            im Hinblick auf das Nutzerkonto gel&#xf6;scht, vorbehaltlich deren Aufbewahrung ist aus handels- oder
            steuerrechtlichen Gr&#xfc;nden entspr. Art. 6 Abs. 1 lit. c DSGVO notwendig.<br><br>
            Im Rahmen der Inanspruchnahme unserer Onlinedienste, speichern wir die IP-Adresse und den Zeitpunkt der
            jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf Grundlage unserer berechtigten Interessen, als auch
            der Nutzer an Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte
            erfolgt grunds&#xe4;tzlich nicht, au&#xdf;er sie ist zur Verfolgung unserer Anspr&#xfc;che erforderlich oder
            es besteht
            hierzu eine gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit. c DSGVO. Die L&#xf6;schung der Daten erfolgt
            nach
            Ablauf gesetzlicher Gew&#xe4;hrleistungs- und vergleichbarer Pflichten, die Erforderlichkeit der
            Aufbewahrung der
            Daten wird
            alle drei Jahre &#xfc;berpr&#xfc;ft; im Fall der gesetzlichen Archivierungspflichten erfolgt die
            L&#xf6;schung nach deren
            Ablauf. Angaben im etwaigen Kundenkonto verbleiben bis zu dessen L&#xf6;schung. Die IP-Adressen werden
            sp&#xe4;testens
            nach 7 Tagen anonymisiert oder gel&#xf6;scht.
        </p>
        <p class="lead"><u><span class="font-weight-bold">Cookies</span></u>
        </p>
        <p class="lead">Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrem Computersystem abgelegt werden.
            Wir setzen keine Cookies auf unserer Internetseite ein.</p>
        <p class="lead"><u><span class="font-weight-bold">Hosting</span></u>
        </p>
        <p class="lead">Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Bereitstellung der folgenden
            Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazit&#xe4;t, Speicherplatz und
            Datenbankdienste, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zwecke des Betriebs
            dieses Onlineangebotes einsetzen. F&#xfc;r diese Zwecke verarbeiten wir, bzw. unser Hostinganbieter Ihre
            Daten
            auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Bereitstellung dieses
            Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss
            Auftragsverarbeitungsvertrag). Wir, bzw. unser Hostinganbieter, erhebt und speichert auf Grundlage unserer
            berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten &#xfc;ber jeden Zugriff auf den
            Server,
            auf dem sich dieser Dienst befindet (sogenannte Serverlogfiles).</p>
        <p class="lead"><u><span class="font-weight-bold">Erhebung von Zugriffsdaten und Logfiles</span></u>
        </p>
        <p class="lead">Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer berechtigten Interessen im Sinne
            des Art. 6 Abs. 1 lit. f. DSGVO Daten &#xfc;ber jeden Zugriff auf den Server, auf dem sich dieser Dienst
            befindet
            (sogenannte Serverlogfiles). Zu den Zugriffsdaten geh&#xf6;ren Datum und Uhrzeit des Abrufs, Name der
            abgerufenen
            Webseite,
            die &#xfc;bertragene Datenmenge, die Meldung &#xfc;ber erfolgreichen Abruf, der Browsertyp nebst Version,
            das
            Betriebssystem des Nutzers, die zuvor besuchte Seite (sog. &#x201e;Referrer URL&#x201c;), die IP-Adresse und
            der
            anfragende Provider. Logfile-Informationen werden aus f&#xfc;r die Dauer von maximal 7 Tagen gespeichert und
            danach gel&#xf6;scht.
            Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endg&#xfc;ltigen
            Kl&#xe4;rung des
            jeweiligen Vorfalls von der L&#xf6;schung ausgenommen.</p>
        <p class="lead"><u><span class="font-weight-bold">Kontaktaufnahme</span></u>
        </p>
        <p class="lead">Bei der Kontaktaufnahme mit uns (z.B. per E-Mail, Telefon oder via sozialer
            Medien) werden die Angaben des Nutzers zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6
            Abs. 1 lit. b) DSGVO in Verbindung mit der von Ihnen erteilten Einwilligung verarbeitet. Sie k&#xf6;nnen
            diese
            Einwilligung jederzeit ohne Angabe von Gr&#xfc;nden widerrufen. Dazu reicht eine formlose Mitteilung per
            E-Mail
            an uns. Die
            Rechtm&#xe4;&#xdf;igkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorg&#xe4;nge bleibt vom Widerruf
            unber&#xfc;hrt.<br><br>
            Die von Ihnen bei der Kontaktaufnahme &#xfc;bermittelten Daten verbleiben bei uns, bis Sie uns zur
            L&#xf6;schung
            auffordern,
            Ihre Einwilligung zur Speicherung widerrufen oder der Zweck f&#xfc;r die Datenspeicherung entf&#xe4;llt
            (z.B. nach
            abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen &#x2013; insbesondere
            Aufbewahrungsfristen &#x2013; bleiben unber&#xfc;hrt. Diese Daten geben wir nicht ohne Ihre Einwilligung
            weiter.</p>
        <p class="lead"><span class="font-weight-bold">4. Wie sicher sind meine Daten</span>
        </p>
        <p class="lead">S&#xe4;mtliche Daten, die Sie uns zur Verf&#xfc;gung stellen, werden aus Sicherheitsgr&#xfc;nden
            mit dem
            SSL-(Secure Socket Layer)-Verfahren verschl&#xfc;sselt und &#xfc;bertragen. Bei SSL handelt es sich um ein
            bew&#xe4;hrtes
            und weltweit verwendetes Verschl&#xfc;sselungssystem, mit dessen Hilfe Ihr Browser Ihre Daten automatisch
            vor
            Absendung an
            uns verschl&#xfc;sselt. Eine verschl&#xfc;sselte Verbindung erkennen Sie daran, dass die Adresszeile des
            Browsers von
            &#x201c;http://&#x201d; auf &#x201c;https://&#x201d; wechselt und an dem Schloss-Symbol in Ihrer
            Browserzeile.</p>

        <p class="lead"><span class="font-weight-bold">5. Welche Rechte habe ich als betroffene Person</span>
        </p>
        <p class="lead">
            Sie k&#xf6;nnen jederzeit<br>
            - Auskunft &#xfc;ber die von Ihnen bei uns gespeicherten bzw. verarbeiteten Daten erhalten.<br>
            - Berichtigung oder L&#xf6;schung Ihrer Daten verlangen.<br>
            - Einschr&#xe4;nkung der Verarbeitung Ihrer Daten verlangen.<br>
            - Widerspruch gegen die Verarbeitung Ihrer Daten erheben.<br>
            - Die &#xdc;bertragbarkeit Ihrer Daten einfordern.<br>
            - Beschwerde bei der Datenschutz Aufsichtsbeh&#xf6;rde einreichen. Ihre zust&#xe4;ndige
            Aufsichtsbeh&#xf6;rde ist die Ihres Wohnortes. Eine Liste der Aufsichtsbeh&#xf6;rden finden Sie <a
                href="https://www.bfdi.bund.de/DE/Service/Anschriften/anschriften_table.html">hier</a>.
        </p>
        <p class="lead"><span class="font-weight-bold">6. Widerspruchsrecht</span></p>
        <p class="lead">Sofern wir Ihre personenbezogenen Daten auf Grundlage unserer &#xfc;berwiegenden berechtigten
            Interessen gem&#xe4;&#xdf; Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeiten, haben Sie das Recht, gem&#xe4;&#xdf;
            Art. 21 DSGVO
            Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit daf&#xfc;r Gr&#xfc;nde
            vorliegen,
            die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im
            letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von
            uns umgesetzt wird.<br><br>
            M&#xf6;chten Sie von Ihrem Widerspruchsrecht Gebrauch machen, gen&#xfc;gt eine E-Mail an die Eingangs
            genannte
            E-Mail-Adresse des f&#xfc;r die Datenverarbeitung Verantwortlichen.</p>
        <p class="lead"><span class="font-weight-bold">7. Wie lange werden meine Daten gespeichert</span></p>
        <p class="lead">Die von uns verarbeiteten Daten werden nach Ma&#xdf;gabe der Art. 17 und 18 DSGVO gel&#xf6;scht
            oder in
            ihrer Verarbeitung eingeschr&#xe4;nkt. Sobald die Daten f&#xfc;r ihre Zweckbestimmung nicht mehr
            erforderlich sind und
            der L&#xf6;schung keine gesetzlichen Aufbewahrungspflichten entgegenstehen, werden die bei uns gespeicherten
            Daten
            gel&#xf6;scht. Sofern die Daten nicht gel&#xf6;scht werden, weil sie f&#xfc;r andere und gesetzlich
            zul&#xe4;ssige Zwecke
            erforderlich sind, wird deren Verarbeitung eingeschr&#xe4;nkt. D.h. die Daten werden gesperrt und nicht
            f&#xfc;r
            andere Zwecke verarbeitet. Das gilt z.B. f&#xfc;r Daten, die aus handels- oder steuerrechtlichen
            Gr&#xfc;nden
            aufbewahrt werden
            m&#xfc;ssen. Nach gesetzlichen Vorgaben in Deutschland erfolgt die Aufbewahrung insbesondere f&#xfc;r 6
            Jahre gem&#xe4;&#xdf; &#xa7;
            257 Abs. 1 HGB sowie f&#xfc;r 10 Jahre gem&#xe4;&#xdf; &#xa7; 147 Abs. 1 AO.</p>
        <p class="lead"><span class="font-weight-bold">8. Analyse- und Marketing-Tools</span></p>
        <p class="lead">Es werden derzeit keine Analyse- und Marketing-Tools eingesetzt.</p>
        <p class="lead"><span class="font-weight-bold">9. Welche sonstigen Dienste und Inhalte Dritter sind
                eingebunden</span></p>
        <p class="lead"><u><span class="font-weight-bold">Font Awesome</span></u></p>
        <p class="lead">Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von
            Fonticons, Inc. bereitgestellt werden. Beim Aufruf einer Seite l&#xe4;dt Ihr Browser die ben&#xf6;tigten Web Fonts in
            ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Dabei kann Ihre IP-Adresse an einen Server
            von &#x201e;Fonticons &#xfc;bertragen werden und im Rahmen des &#xfc;blichen Weblogs gespeichert werden.<br><br>

            Weitere Informationen entnehmen Sie bitte der <a href="https://fontawesome.com/privacy">Datenschutzerkl&#xe4;rung von Fonticons</a>.<br><br>

            Die Nutzung von Web Fonts von Font Awesome erfolgt im Interesse einer einheitlichen und ansprechenden
            Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit.
            f DSGVO dar.</p>


    </div>
</template>
<script>
export default {
    name: 'ShopAboutDatenschutz'
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>