<template>
<div id="all">
    <div id="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <!-- breadcrumb-->
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link to="/">Home</router-link>
                            </li>
                            <li aria-current="page" class="breadcrumb-item active">
                                {{this.$route.name}}
                            </li>
                        </ol>
                    </nav>
                </div>
<!--                 <div class="col-lg-3">
                    <div class="card sidebar-menu mb-4">
                        <div class="card-header">
                            <h3 class="h4 card-title">Über uns</h3>
                        </div>
                        <div class="card-body">
                            <ul class="nav nav-pills flex-column">
                                <li>
                                    <router-link to="/about/impressum" class="nav-link">Impressum</router-link>
                                </li>
                                <li>
                                    <router-link to="/about/agb" class="nav-link">AGB</router-link>
                                </li>
                                <li>
                                    <router-link to="/about/widerruf" class="nav-link">Widerrufsbelehrung</router-link>
                                </li>
                                <li>
                                    <router-link to="/about/datenschutz" class="nav-link">Datenschutzerklärung</router-link>
                                </li>
                                <li>
                                    <router-link to="/about/zahlungsarten" class="nav-link">Zahlungsarten</router-link>
                                </li>
                                <li>
                                    <router-link to="/about/versandinformationen" class="nav-link">Versandkosten</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> -->
                <div class="col-lg-12">
                    <router-view />
                </div>
                <!-- /.col-lg-9-->
            </div>
        </div>
    </div>
</div>
</template>
<script>
    export default {
        name: 'ShopAbout'
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
