<template>
    <div id="text-page">
        <h3>Allgemeine Gesch&#xe4;ftsbedingungen</h3>
        <!-- <p class="lead"><span class="font-weight-bold">Allgemeine Geschäftsbedingungen</span></p>-->
        <p class="lead">
            <span>&#xa7; 1 Geltungsbereich</span><br />
            <span>&#xa7; 2 Zustandekommen des Vertrages</span><br />
            <span>&#xa7; 3 Preise und Zahlungsbedingungen</span><br />
            <span>&#xa7; 4 Liefer- und Versandbedingungen</span><br />
            <span>&#xa7; 5 Widerrufsrecht</span><br />
            <span>&#xa7; 6 Eigentumsvorbehalt</span><br />
            <span>&#xa7; 7 Gew&#xe4;hrleistung</span><br />
            <span>&#xa7; 8 Haftungsbeschr&#xe4;nkung</span><br />
            <span>&#xa7; 9 Anwendbares Recht</span><br />
            <span>&#xa7; 10 Gerichtsstand</span><br />
            <span>&#xa7; 11 Informationen zur Online-Streitbeilegung</span><br />
        </p>
        <p class="lead"><span class="font-weight-bold">&#xa7; 1 Geltungsbereich</span></p>
        <p class="lead">
            (1) Diese Allgemeinen Gesch&#xe4;ftsbedingungen (nachfolgend &quot;AGB&quot; genannt) von Ranu Hasuk (nachfolgend
            &quot;Verk&#xe4;ufer&quot; genannt),
            gelten f&#xfc;r s&#xe4;mtliche Vertr&#xe4;ge, die ein Verbraucher oder Unternehmer (nachfolgend &quot;Kunde&quot; genannt) mit dem
            Verk&#xe4;ufer &#xfc;ber die
            vom Verk&#xe4;ufer in seinem Online-Shop chang-asia-shop.de angebotenen Waren abschlie&#xdf;t. Der Einbeziehung von
            eigenen Bedingungen
            des Kunden wird widersprochen, es sei denn, es ist etwas anderes vereinbart.</p>
        <p class="lead">(2) Verbraucher ist jede nat&#xfc;rliche Person, die ein Rechtsgesch&#xe4;ft zu einem Zweck abschlie&#xdf;t,
            der &#xfc;berwiegend weder ihrer
            gewerblichen noch ihrer selbst&#xe4;ndigen beruflichen T&#xe4;tigkeit zugerechnet werden kann.</p>
        <p class="lead">(3) Unternehmer ist eine nat&#xfc;rliche oder juristische Person oder eine rechtsf&#xe4;hige
            Personengesellschaft, die bei Abschluss
            eines Rechtsgesch&#xe4;fts in Aus&#xfc;bung ihrer gewerblichen oder selbst&#xe4;ndigen beruflichen T&#xe4;tigkeit handelt.</p>

        <p class="lead"><span class="font-weight-bold">&#xa7; 2 Zustandekommen des Vertrages</span></p>
        <p class="lead">
            (1) Die Pr&#xe4;sentation der Waren insbesondere im Onlineshop stellt noch kein bindendes Angebot des Verk&#xe4;ufers
            dar.</p>
        <p class="lead">
            (2) Zun&#xe4;chst legt der Kunde die ausgew&#xe4;hlte Ware in den sogenannten &quot;Warenkorb&quot;. In der Navigationsleiste
            kann der Kunde &#xfc;ber die entsprechende Schaltfl&#xe4;che den &#x201c;Warenkorb&#x201d; aufrufen und dort jederzeit &#xc4;nderungen
            vornehmen.
            Nach Bet&#xe4;tigen der Schaltfl&#xe4;che &quot;Zur Kasse&quot; beginnt der Bestellvorgang, in welchem die pers&#xf6;nlichen Daten
            sowie die Zahlungs- und Versandbedingungen erfasst werden. Am Ende des Bestellvorgangs erscheint eine
            Zusammenfassung der Bestell-
            und Vertragsdaten. Hier hat der Kunde die M&#xf6;glichkeit, s&#xe4;mtliche Angaben nochmals zu &#xfc;berpr&#xfc;fen, zu &#xe4;ndern
            oder den Kauf
            abzubrechen. Erst nach Best&#xe4;tigung dieser Bestell- und Vertragsdaten durch Klick auf den den Bestellvorgang
            abschlie&#xdf;enden Button gibt der Kunde ein verbindliches Angebot &#xfc;ber den Kauf der im Warenkorb enthaltenen
            Waren ab.
            Der Verk&#xe4;ufer best&#xe4;tigt den Eingang des Angebots des Kunden per E-Mail, was aber nicht die Annahme des
            Angebots darstellt.<br />
            Der Kunde hat auch die M&#xf6;glichkeit, dieses Angebot per Email, Messenger, postalisch oder telefonisch
            gegen&#xfc;ber dem Verk&#xe4;ufer
            abgeben.</p>
        <p class="lead">
            (3) Der Verk&#xe4;ufer nimmt das Angebot des Kunden an durch &#xdc;bersendung einer schriftlichen Auftragsbest&#xe4;tigung
            oder einer Auftragsbest&#xe4;tigung in Textform (E-Mail).<br />
            Die Frist zur Annahme des Angebots beginnt am Tag nach der Absendung des Angebots durch den Kunden und endet
            mit dem Ablauf des f&#xfc;nften Tages,
            der auf die Absendung des Angebots folgt. Nimmt der Verk&#xe4;ufer das Angebot des Kunden innerhalb der
            vorgenannten Frist nicht an, stellt dies die Ablehnung
            des Angebots dar. Der Kunde ist dann nicht mehr an seine Willenserkl&#xe4;rung gebunden.</p>
        <p class="lead">(4) Der Vertragstext des jeweils zwischen dem Verk&#xe4;ufer und dem Kunden geschlossenen Vertrages
            wird durch den Verk&#xe4;ufer auf den internen Systemen des Verk&#xe4;ufers gespeichert. Die Allgemeinen
            Gesch&#xe4;ftsbedingungen kann der Kunde jederzeit auf dieser Seite einsehen. Die Bestelldaten, die
            Widerrufsbelehrung sowie die AGB werden dem Kunden per Email zugesendet. Nach Abschluss der Bestellung ist
            der Vertragstext dem Kunden &#xfc;ber dessen Kundenlogin kostenlos zug&#xe4;nglich, sofern dieser ein Kundenkonto
            er&#xf6;ffnet hat.</p>
        <p class="lead">(5) Die Vertragssprache ist Deutsch.</p>
        <p class="lead">(6) Die Abwicklung der Bestellung und &#xdc;bermittlung aller im Zusammenhang mit dem Vertragsschluss
            erforderlichen Informationen erfolgt per E-Mail zum Teil automatisiert. Der Kunde hat deshalb
            sicherzustellen,
            dass die von ihm bei uns hinterlegte E-Mail-Adresse zutreffend ist, der Empfang der E-Mails technisch
            sichergestellt und insbesondere nicht durch SPAM-Filter verhindert wird.</p>
        <p class="lead"><span class="font-weight-bold">&#xa7; 3 Preise und Zahlungsbedingungen</span></p>
        <p class="lead">(1) Die in den jeweiligen Angeboten angef&#xfc;hrten Artikelpreise sowie die Versandkosten stellen
            Gesamtpreise dar und beinhalten alle Preisbestandteile einschlie&#xdf;lich der gesetzlichen Umsatzsteuer.</p>
        <p class="lead">(2) Die jeweiligen Versandkosten werden dem Kunden im Bestellprozess konkret angegeben und sind
            von diesem zu tragen.</p>
        <p class="lead">(3) Die Vertragsparteien vereinbaren, dass im Fall des Widerrufs der Kunde die unmittelbaren
            Kosten der R&#xfc;cksendung zu tragen hat.</p>
        <p class="lead">(4) Die zur Verf&#xfc;gung stehenden Zahlungsarten stehen dem Kunden w&#xe4;hrend des Bestellvorgangs zur
            Auswahl.</p>
        <p class="lead">(5) Bei Vorkasse per Bank&#xfc;berweisung oder per PayPal ist die Zahlung, wenn nichts anderes
            vereinbart ist, unmittelbar nach Vertragsschluss f&#xe4;llig.</p>
        <p class="lead"><span class="font-weight-bold">&#xa7; 4 Liefer- und Versandbedingungen</span></p>
        <p class="lead">(1) Eine Lieferung der bestellten Waren erfolgt nur an Kunden, welche eine Lieferadresse in
            Deutschland oder in einem Land der Europ&#xe4;ischen Union haben:<br>
            Belgien, Bulgarien, D&#xe4;nemark (au&#xdf;er F&#xe4;r&#xf6;er, Gr&#xf6;nland), Estland, Finnland (au&#xdf;er &#xc5;landinseln), Frankreich
            (au&#xdf;er &#xfc;berseeische Gebiete und Departements),
            Griechenland (au&#xdf;er Berg Athos), Irland, Italien (au&#xdf;er Livigno und Campione d&apos;Italia), Kroatien, Lettland,
            Litauen, Luxemburg, Malta, Monaco,
            Niederlande (au&#xdf;er au&#xdf;ereurop&#xe4;ische Gebiete), &#xd6;sterreich, Polen, Portugal, Rum&#xe4;nien, Schweden, Slowakei,
            Slowenien, Spanien (au&#xdf;er Kanarische Inseln, Ceuta und Melilla),
            Tschechische Republik, Ungarn, Zypern.<br>
            Eine Lieferung in andere L&#xe4;nder erfolgt nicht.</p>

        <p class="lead">(2) Der Versand innerhalb Deutschlands erfolgt durch DHL (Deutsche Post AG) bei einem
            Versandgewicht bis 5 kg und durch Hermes Versand bei einem Versandgewicht gr&#xf6;&#xdf;er 5 kg. Der Versand ins
            Ausland erfolgt immer &#xfc;ber DHL (Deutsche Post AG).</p>
        <p class="lead">(3) Die Versandkosten f&#xfc;r einen Versand innerhalb Deutschlands betragen 6,99 EUR <u>je Paket</u>
            mit einem maximalen Versandgewicht von 25kg.<br><br>
            Der Versand ins EU-Ausland berechnet sich <u>je Paket</u> nach den aktuell g&#xfc;ltigen Preisen von DHL
            (Deutsche Post AG):<br>
        <table>
            <tr>
                <td>Paket bis 2 kg</td>
                <td class="text-right pl-4">14,49 EUR</td>
            </tr>
            <tr>
                <td>Paket bis 5 kg</td>
                <td class="text-right pl-4">16,49 EUR</td>
            </tr>
            <tr>
                <td>Paket bis 10 kg</td>
                <td class="text-right pl-4">21,49 EUR</td>
            </tr>
            <tr>
                <td>Paket bis 20 kg</td>
                <td class="text-right pl-4">32,49 EUR</td>
            </tr>
            <tr>
                <td>Paket bis 31,5 kg</td>
                <td class="text-right pl-4">45,49 EUR</td>
            </tr><br>
        </table>Die Ermittlung des Versandgewichts sowie die Anzahl der daraus resultierenden Pakete erfolgt
        automatisiert w&#xe4;hrend des Bestellvorgangs und wird auf der Bestell&#xfc;bersichtsseite angezeigt.
        </p>
        <p class="lead">(4) Die von uns angegebene Lieferzeit berechnet sich vom Zeitpunkt der Auftragsbest&#xe4;tigung,
            vorherige Zahlung des Kaufpreises vorausgesetzt.
            Bei Zahlungsart Vorkasse berechnet sich die Lieferzeit ab dem Zeitpunkt des Zahlungseingangs. Sofern f&#xfc;r die
            jeweilige Ware im Rahmen des Bestellvorgangs keine abweichende Lieferzeit angegeben ist,
            gelten folgende Lieferzeiten:<br>
        <table>
            <tr>
                <td>Deutschland (bis 5kg)</td>
                <td class="text-right pl-4">1-2 Werktage</td>
            </tr>
            <tr>
                <td>Deutschland (&gt; 5kg)</td>
                <td class="text-right pl-4">2-5 Werktage</td>
            </tr>
            <tr>
                <td>Belgien</td>
                <td class="text-right pl-4">ca. 2 Werktage</td>
            </tr>
            <tr>
                <td>Bulgarien</td>
                <td class="text-right pl-4">ca. 4-5 Werktage</td>
            </tr>
            <tr>
                <td>D&#xe4;nemark</td>
                <td class="text-right pl-4">ca. 2-3 Werktage</td>
            </tr>
            <tr>
                <td>Estland</td>
                <td class="text-right pl-4">ca. 3-4 Werktage</td>
            </tr>
            <tr>
                <td>Finnland</td>
                <td class="text-right pl-4">ca. 3-5 Werktage</td>
            </tr>
            <tr>
                <td>Frankreich</td>
                <td class="text-right pl-4">ca. 2-3 Werktage</td>
            </tr>
            <tr>
                <td>Griechenland</td>
                <td class="text-right pl-4">ca. 5-6 Werktage</td>
            </tr>
            <tr>
                <td>Irland</td>
                <td class="text-right pl-4">ca. 3-4 Werktage</td>
            </tr>
            <tr>
                <td>Italien</td>
                <td class="text-right pl-4">ca. 2-4 Werktage</td>
            </tr>
            <tr>
                <td>Kroatien</td>
                <td class="text-right pl-4">ca. 2-3 Werktage</td>
            </tr>
            <tr>
                <td>Lettland</td>
                <td class="text-right pl-4">ca. 3-4 Werktage</td>
            </tr>
            <tr>
                <td>Litauen</td>
                <td class="text-right pl-4">ca. 3-4 Werktage</td>
            </tr>
            <tr>
                <td>Luxemburg</td>
                <td class="text-right pl-4">ca. 2-3 Werktage</td>
            </tr>
            <tr>
                <td>Malta</td>
                <td class="text-right pl-4">ca. 5-7 Werktage</td>
            </tr>
            <tr>
                <td>Monaco</td>
                <td class="text-right pl-4">ca. 3-4 Werktage</td>
            </tr>
            <tr>
                <td>Niederlande</td>
                <td class="text-right pl-4">ca. 1-2 Werktage</td>
            </tr>
            <tr>
                <td>&#xd6;sterreich</td>
                <td class="text-right pl-4">ca. 2 Werktage</td>
            </tr>
            <tr>
                <td>Polen</td>
                <td class="text-right pl-4">ca. 2-3 Werktage</td>
            </tr>
            <tr>
                <td>Portugal</td>
                <td class="text-right pl-4">ca. 3-5 Werktage</td>
            </tr>
            <tr>
                <td>Rum&#xe4;nien</td>
                <td class="text-right pl-4">ca. 3-4 Werktage</td>
            </tr>
            <tr>
                <td>Schweden</td>
                <td class="text-right pl-4">ca. 2-4 Werktage</td>
            </tr>
            <tr>
                <td>Slowakei</td>
                <td class="text-right pl-4">ca. 2-3 Werktage</td>
            </tr>
            <tr>
                <td>Slowenien</td>
                <td class="text-right pl-4">ca. 2-3 Werktage</td>
            </tr>
            <tr>
                <td>Spanien</td>
                <td class="text-right pl-4">ca. 3-4 Werktage</td>
            </tr>
            <tr>
                <td>Tschechische Republik</td>
                <td class="text-right pl-4">ca. 2-3 Werktage</td>
            </tr>
            <tr>
                <td>Ungarn</td>
                <td class="text-right pl-4">ca. 3 Werktage</td>
            </tr>
            <tr>
                <td>Zypern, Republik</td>
                <td class="text-right pl-4">ca. 8-12 Werktage</td>
            </tr>



        </table>

        </p>
        <p class="lead">(5) Soweit der Kunde Verbraucher ist, ist gesetzlich geregelt, dass die Gefahr des zuf&#xe4;lligen
            Untergangs und der zuf&#xe4;lligen Verschlechterung der verkauften Sache w&#xe4;hrend der Versendung erst mit der
            &#xdc;bergabe der Ware an den Kunden &#xfc;bergeht, unabh&#xe4;ngig davon, ob die Versendung versichert oder unversichert
            erfolgt. Dies gilt nicht, wenn der Kunde eigenst&#xe4;ndig ein nicht vom Unternehmer benanntes
            Transportunternehmen oder eine sonst zur Ausf&#xfc;hrung der Versendung bestimmte Person beauftragt hat.<br>
            Sind Sie Unternehmer, erfolgt die Lieferung und Versendung auf Ihre Gefahr.
        </p>
        <p class="lead"><span class="font-weight-bold">&#xa7; 5 Widerrufsrecht</span></p>
        <p class="lead">
            (1) Ist der Kunde Verbraucher, steht ihm grunds&#xe4;tzlich ein Widerrufsrecht zu.
        </p>
        <p class="lead">
            (2) F&#xfc;r das Widerrufsrecht gilt die Widerrufsbelehrung des Verk&#xe4;ufers.
        </p>
        <p class="lead"><span class="font-weight-bold">&#xa7; 6 Eigentumsvorbehalt</span></p>
        <p class="lead">(1) Die Ware bleibt bei Vertr&#xe4;gen mit Verbrauchern bis zur vollst&#xe4;ndigen Bezahlung im Eigentum
            des Verk&#xe4;ufers.</p>
        <p class="lead">(2) Die Ware bleibt bei Vertr&#xe4;gen mit Unternehmern bis zur vollst&#xe4;ndigen Begleichung aller
            Forderungen aus einer laufenden Gesch&#xe4;ftsbeziehung im Eigentum des Verk&#xe4;ufers.</p>
        <p class="lead">(3) Sofern der Kunde als Unternehmer handelt, ist er zur Weiterver&#xe4;u&#xdf;erung der Vorbehaltsware im
            ordnungsgem&#xe4;&#xdf;en Gesch&#xe4;ftsbetrieb berechtigt.
            Die aus der Weiterver&#xe4;u&#xdf;erung entstehenden Forderungen gegen Dritte tritt der Kunde in H&#xf6;he des jeweiligen
            Rechnungswertes (einschlie&#xdf;lich Umsatzsteuer) im Voraus an den Verk&#xe4;ufer ab. Diese Abtretung gilt unabh&#xe4;ngig
            davon, ob die Vorbehaltsware ohne oder nach ihrer Verarbeitung weiterverkauft wurde. Der Kunde darf auch
            nach der Abtretung Forderungen einziehen, dies ber&#xfc;hrt jedoch nicht das Recht des Verk&#xe4;ufers, die
            Forderungen selbst einzuziehen. Der Verk&#xe4;ufer wird jedoch von dem Einzug der Forderungen absehen, so lange
            der Kunde seinen Zahlungsverpflichtungen dem Verk&#xe4;ufer gegen&#xfc;ber nachkommt, nicht in Zahlungsverzug ger&#xe4;t
            und kein Antrag auf Er&#xf6;ffnung eines Insolvenzverfahrens gegen den Kunden gestellt ist.</p>
        <p class="lead"><span class="font-weight-bold">&#xa7; 7 Gew&#xe4;hrleistung</span></p>
        <p class="lead">(1) Hinsichtlich der Gew&#xe4;hrleistung gelten die Vorschriften der gesetzlichen M&#xe4;ngelhaftung,
            soweit nichts Abweichendes vereinbart worden ist.</p>
        <p class="lead">(2) Sofern der Kunde als Unternehmer handelt,<br />
            - hat der Verk&#xe4;ufer die Wahl der Art der Nacherf&#xfc;llung;<br />
            - sind bei gebrauchter Ware die Rechte und Anspr&#xfc;che wegen M&#xe4;ngeln grunds&#xe4;tzlich ausgeschlossen;<br />
            - betr&#xe4;gt bei neuer Ware die Verj&#xe4;hrungsfrist f&#xfc;r M&#xe4;ngel ein Jahr ab Gefahr&#xfc;bergang;<br />
            - beginnt die Verj&#xe4;hrung auch nicht erneut, wenn im Rahmen der M&#xe4;ngelhaftung eine Ersatzlieferung erfolgt
            ist.</p>
        <p class="lead">(3) Die gesetzlichen Verj&#xe4;hrungsfristen f&#xfc;r den R&#xfc;ckgriffsanspruch eines Unternehmers &#xa7; 478 BGB
            bleiben unber&#xfc;hrt.</p>
        <p class="lead">(4) Wenn der Kunde als Kaufmann i.S.d. &#xa7; 1 HGB handelt, treffen ihn die kaufm&#xe4;nnischen
            Untersuchungs- und R&#xfc;gepflichten gem&#xe4;&#xdf; &#xa7; 377 HGB.</p>
        <p class="lead">(5) Sofern der Kunde als Verbraucher handelt, wird er gebeten, erhaltene Ware mit
            offensichtlichen Transportsch&#xe4;den bei dem Zusteller zu reklamieren und den Verk&#xe4;ufer hiervon in Kenntnis zu
            setzen. Es wird ausdr&#xfc;cklich klargestellt, dass die gesetzlichen oder vertraglichen M&#xe4;ngelanspr&#xfc;che des
            Kunden nicht
            ber&#xfc;hrt werden, wenn der Kunde dieser Bitte nicht nachkommt.</p>
        <p class="lead"><span class="font-weight-bold">&#xa7; 8 Haftungsbeschr&#xe4;nkung</span></p>
        <p class="lead">(1) Der Verk&#xe4;ufer schlie&#xdf;t die Haftung f&#xfc;r leicht fahrl&#xe4;ssige Pflichtverletzungen aus, sofern diese keine
            vertragswesentlichen Pflichten, Sch&#xe4;den aus der Verletzung des Lebens, des K&#xf6;rpers oder der Gesundheit,
            Garantien oder Anspr&#xfc;che nach dem Produkthaftungsgesetz (ProdHaftG) betreffen. Gleiches gilt f&#xfc;r
            Pflichtverletzungen der Erf&#xfc;llungsgehilfen und der gesetzlichen Vertreter des Verk&#xe4;ufers. Zu den
            vertragswesentlichen Pflichten geh&#xf6;rt insbesondere die Pflicht, dem Kunden die Sache zu &#xfc;bergeben und dem Kunden das
            Eigentum daran zu verschaffen. Weiterhin hat der Verk&#xe4;ufer dem Kunden die Sache frei von Sach- und Rechtsm&#xe4;ngeln zu
            verschaffen.</p>
        <p class="lead"><span class="font-weight-bold">&#xa7; 9 Anwendbares Recht</span></p>
        <p class="lead">(1) Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss der Gesetze &#xfc;ber den
            internationalen Kauf beweglicher Waren.
            Die gesetzlichen Vorschriften zur Beschr&#xe4;nkung der Rechtswahl und zur Anwendbarkeit zwingender Vorschriften
            insbesondere des Staates, in dem der Kunde als Verbraucher seinen gew&#xf6;hnlichen Aufenthalt hat, bleiben
            unber&#xfc;hrt.</p>
        <p class="lead"><span class="font-weight-bold">&#xa7; 10 Gerichtsstand</span></p>
        <p class="lead">(1) Sofern der Kunde Kaufmann ist, eine juristische Person des &#xf6;ffentlichen Rechts oder
            &#xf6;ffentlich-rechtliches Sonderverm&#xf6;gen mit Sitz im Hoheitsgebiet der Bundesrepublik Deutschland, ist der
            ausschlie&#xdf;liche Gerichtsstand f&#xfc;r alle Streitigkeiten aus diesem Vertrag der Gesch&#xe4;ftssitz des Verk&#xe4;ufers.
            Sofern der Kunde seinen Sitz au&#xdf;erhalb des Hoheitsgebiets der Bundesrepublik Deutschland hat, ist der
            Gesch&#xe4;ftssitz des Verk&#xe4;ufers der ausschlie&#xdf;liche Gerichtsstand, wenn dieser Vertrag oder Anspr&#xfc;che aus
            diesem Vertrag der beruflichen oder gewerblichen T&#xe4;tigkeit des Kunden zugerechnet werden k&#xf6;nnen.
            Dennoch ist der Verk&#xe4;ufer in den vorgenannten F&#xe4;llen auch berechtigt, das Gericht am Sitz des Kunden
            anzurufen.</p>
        <p class="lead"><span class="font-weight-bold">&#xa7; 11 Informationen zur Online-Streitbeilegung</span></p>
        <p class="lead">(1) Die Europ&#xe4;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit. Die
            Plattform finden Sie unter <a href="https://ec.europa.eu/consumers/odr/">ec.europa.eu/consumers/odr/</a>.
            Wir sind weder bereit noch verpflichtet, an einem Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle teilzunehmen.</p>
        <br /><br />
    </div>
</template>
<script>
export default {
    name: 'ShopAboutAGB'
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>