<template>
  <div id="all">
    <div id="content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!-- breadcrumb-->
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li aria-current="page" class="breadcrumb-item active">
                  Seite nicht gefunden
                </li>
              </ol>
            </nav>
            <div id="error-page" class="row">
              <div class="col-md-6 mx-auto">
                <div class="box text-center py-5">
                  <p class="text-center">
                    <img src="../assets/img/Logo-Chang-Asia-Shop-7-navbar.png" alt="Logo Chang Asia Shop" />
                  </p>
                  <h3>
                    Es tut uns leid, aber die aufgerufene Seite existiert nicht.
                  </h3>
                  <h4 class="text-muted">Error 404 - Seite nicht gefunden</h4>
                  <p class="text-center">
                    Um fortzufahren, w&auml;hlen Sie bitte oben einen
                    <strong>Men&uuml;punkt</strong> aus.
                  </p>
                  <p class="buttons">
                    <a href="index.php" class="btn btn-primary"
                      ><i class="fa fa-home"></i> Startseite</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    export default {
        name: 'Shop404'
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
