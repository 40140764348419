<template>
    <div id="text-page">
        <h3>Versandinformationen</h3>
        <p class="lead"><span class="font-weight-bold">1. Versandkosten</span></p>

        <p class="lead">Die Versandkosten für einen Versand innerhalb Deutschlands betragen 6,99 EUR <u>je Paket</u>
            mit einem maximalen Versandgewicht von 25kg.<br><br>
            Der Versand ins EU-Ausland berechnet sich <u>je Paket</u> nach den aktuell gültigen Preisen von DHL
            (Deutsche Post AG):<br>
        <table>
            <tr>
                <td>Paket bis 2 kg</td>
                <td class="text-right pl-4">14,49 EUR</td>
            </tr>
            <tr>
                <td>Paket bis 5 kg</td>
                <td class="text-right pl-4">16,49 EUR</td>
            </tr>
            <tr>
                <td>Paket bis 10 kg</td>
                <td class="text-right pl-4">21,49 EUR</td>
            </tr>
            <tr>
                <td>Paket bis 20 kg</td>
                <td class="text-right pl-4">32,49 EUR</td>
            </tr>
            <tr>
                <td>Paket bis 31,5 kg</td>
                <td class="text-right pl-4">45,49 EUR</td>
            </tr><br>
        </table>Die Ermittlung des Versandgewichts sowie die Anzahl der daraus resultierenden Pakete erfolgt
        automatisiert während des Bestellvorgangs und wird auf der Bestellübersichtsseite angezeigt.
        </p>

        <p class="lead"><span class="font-weight-bold">2. Lieferung</span></p>
        <p class="lead">Eine Lieferung der bestellten Waren erfolgt nur an Kunden, welche eine Lieferadresse in Deutschland oder in einem Land der Europäischen Union haben:<br>
        Belgien, Bulgarien, Dänemark (außer Färöer, Grönland), Estland, Finnland (außer Ålandinseln), Frankreich (außer überseeische Gebiete und Departements), 
        Griechenland (außer Berg Athos), Irland, Italien (außer Livigno und Campione d'Italia), Kroatien, Lettland, Litauen, Luxemburg, Malta, Monaco,
        Niederlande (außer außereuropäische Gebiete), Österreich, Polen, Portugal, Rumänien, Schweden, Slowakei, Slowenien, Spanien (außer Kanarische Inseln, Ceuta und Melilla),
        Tschechische Republik, Ungarn, Zypern.<br>
        Eine Lieferung in andere Länder erfolgt nicht.</p>
        <p class="lead">Der Versand innerhalb Deutschlands erfolgt durch DHL (Deutsche Post AG) bei einem
            Versandgewicht bis 5 kg und durch Hermes Versand bei einem Versandgewicht größer 5 kg. Der Versand ins
            Ausland erfolgt immer über DHL (Deutsche Post AG).</p>
            <p class="lead">Die von uns angegebene Lieferzeit berechnet sich vom Zeitpunkt der Auftragsbestätigung, vorherige Zahlung des Kaufpreises vorausgesetzt. 
            Bei Zahlungsart Vorkasse berechnet sich die Lieferzeit ab dem Zeitpunkt des Zahlungseingangs. Sofern für die jeweilige Ware im Rahmen des Bestellvorgangs keine abweichende Lieferzeit angegeben ist,
             gelten folgende Lieferzeiten:<br>
             <table><tr><td>Deutschland (bis 5kg)</td><td class="text-right pl-4">1-2 Werktage</td></tr>
                <tr><td>Deutschland (&gt; 5kg)</td><td class="text-right pl-4">2-5 Werktage</td></tr>
                <tr><td>Belgien</td><td class="text-right pl-4">ca. 2 Werktage</td></tr>
                <tr><td>Bulgarien</td><td class="text-right pl-4">ca. 4-5 Werktage</td></tr>
                <tr><td>Dänemark</td><td class="text-right pl-4">ca. 2-3 Werktage</td></tr>
                <tr><td>Estland</td><td class="text-right pl-4">ca. 3-4 Werktage</td></tr>
                <tr><td>Finnland</td><td class="text-right pl-4">ca. 3-5 Werktage</td></tr>
                <tr><td>Frankreich</td><td class="text-right pl-4">ca. 2-3 Werktage</td></tr>
                <tr><td>Griechenland</td><td class="text-right pl-4">ca. 5-6 Werktage</td></tr>
                <tr><td>Irland</td><td class="text-right pl-4">ca. 3-4 Werktage</td></tr>
                <tr><td>Italien</td><td class="text-right pl-4">ca. 2-4 Werktage</td></tr>
                <tr><td>Kroatien</td><td class="text-right pl-4">ca. 2-3 Werktage</td></tr>
                <tr><td>Lettland</td><td class="text-right pl-4">ca. 3-4 Werktage</td></tr>
                <tr><td>Litauen</td><td class="text-right pl-4">ca. 3-4 Werktage</td></tr>
                <tr><td>Luxemburg</td><td class="text-right pl-4">ca. 2-3 Werktage</td></tr>
                <tr><td>Malta</td><td class="text-right pl-4">ca. 5-7 Werktage</td></tr>
                <tr><td>Monaco</td><td class="text-right pl-4">ca. 3-4 Werktage</td></tr>
                <tr><td>Niederlande</td><td class="text-right pl-4">ca. 1-2 Werktage</td></tr>
                <tr><td>Österreich</td><td class="text-right pl-4">ca. 2 Werktage</td></tr>
                <tr><td>Polen</td><td class="text-right pl-4">ca. 2-3 Werktage</td></tr>
                <tr><td>Portugal</td><td class="text-right pl-4">ca. 3-5 Werktage</td></tr>
                <tr><td>Rumänien</td><td class="text-right pl-4">ca. 3-4 Werktage</td></tr>
                <tr><td>Schweden</td><td class="text-right pl-4">ca. 2-4 Werktage</td></tr>
                <tr><td>Slowakei</td><td class="text-right pl-4">ca. 2-3 Werktage</td></tr>
                <tr><td>Slowenien</td><td class="text-right pl-4">ca. 2-3 Werktage</td></tr>
                <tr><td>Spanien</td><td class="text-right pl-4">ca. 3-4 Werktage</td></tr>
                <tr><td>Tschechische Republik</td><td class="text-right pl-4">ca. 2-3 Werktage</td></tr>
                <tr><td>Ungarn</td><td class="text-right pl-4">ca. 3 Werktage</td></tr>
                <tr><td>Zypern, Republik</td><td class="text-right pl-4">ca. 8-12 Werktage</td></tr>
  
            
            
            </table>

            </p>            
    </div>
</template>
<script>
export default {
    name: 'ShopAboutVersandkosten'
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>