<template>
<div id="text-page">
    <h3>Widerrufsbelehrung</h3>
    <p class="lead"><span class="font-weight-bold">Widerrufsrecht</span><br />
     Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gr&uuml;nden diesen Vertrag zu widerrufen.</p>
    <p class="lead">Die Widerrufsfrist betr&auml;gt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Bef&ouml;rderer ist, die Waren in Besitz genommen haben bzw. hat.</p>
    <p class="lead">Um Ihr Widerrufsrecht auszu&uuml;ben, m&uuml;ssen Sie uns (Chang Asia Shop, Ranu Hasuk, Bockrisweg 11, 22761 Hamburg, service@chang-asia-shop.de , +4901708971613 ) mittels einer eindeutigen Erkl&auml;rung (z. B. ein mit der Post versandter Brief oder eine E-Mail) &uuml;ber Ihren Entschluss, diesen Vertrag zu widerrufen, informieren.</p>

    <p class="lead">Sie k&ouml;nnen daf&uuml;r das beigef&uuml;gte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.</p>
    <p class="lead">Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung &uuml;ber die Aus&uuml;bung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>
    <p class="lead"><span class="font-weight-bold">Folgen des Widerrufs</span><br />
    Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschlie&szlig;lich der Lieferkosten (mit Ausnahme der zus&auml;tzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, g&uuml;nstige Standardlieferung gew&auml;hlt haben), unverz&uuml;glich und sp&auml;testens binnen vierzehn Tagen ab dem Tag zur&uuml;ckzuzahlen, an dem die Mitteilung &uuml;ber Ihren Widerruf dieses Vertrags bei uns eingegangen ist. F&uuml;r diese R&uuml;ckzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der urspr&uuml;nglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdr&uuml;cklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser R&uuml;ckzahlung Entgelte berechnet. Wir k&ouml;nnen die R&uuml;ckzahlung verweigern, bis wir die Waren wieder zur&uuml;ckerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zur&uuml;ckgesandt haben, je nachdem, welches der fr&uuml;here Zeitpunkt ist.</p>
    <p class="lead">Sie haben die Waren unverz&uuml;glich in jedem Fall sp&auml;testens binnen vierzehn Tagen ab dem Tag, an dem Sie uns &uuml;ber den Widerruf dieses Vertrags unterrichten, an uns zur&uuml;ckzusenden oder zu &uuml;bergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Sie tragen die unmittelbaren Kosten der R&uuml;cksendung der Waren. &bdquo;Sie m&uuml;ssen f&uuml;r einen etwaigen Werteverlust der Waren nur aufkommen, wenn dieser Werteverlust auf einen zur Pr&uuml;fung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zur&uuml;ckzuf&uuml;hren ist.</p>
    <p class="lead">Das Widerrufsrecht besteht nicht bei den folgenden Vertr&auml;gen:<ul>
            <li>Vertr&auml;ge zur Lieferung von Waren, die schnell verderben k&ouml;nnen oder deren Verfallsdatum schnell &uuml;berschritten w&uuml;rde.</li>
            <li>Vertr&auml;ge zur Lieferung versiegelter Waren, die aus Gr&uuml;nden des Gesundheitsschutzes oder der Hygiene nicht zur R&uuml;ckgabe geeignet sind, wenn ihre Versiegelung nach der Lieferung entfernt wurde.</li>
        </ul>
    </p>
    <div class="px-5" style="border: 1px solid black">
    <p class="h4 text-center">Muster-Widerrufsformular</p>
    <p class="lead">(Wenn Sie den Vertrag widerrufen wollen, dann f&uuml;llen Sie bitte dieses Formular aus und senden Sie es zur&uuml;ck.)<br /><br />
        <ul class="list-group">
            <li>An: Ranu Hasuk, Bockrisweg 11, 22761 Hamburg, Deutschland, service@chang-asia-shop.de</li>
            <li>Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag &uuml;ber den Kauf der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)</li>
            <li>Bestellt am (*)/erhalten am (*)</li>
            <li>Name des/der Verbraucher(s)</li>
            <li>Anschrift des/der Verbraucher(s)</li>
            <li>Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</li>
            <li>Datum</li>
        </ul>
        (*) Unzutreffendes streichen.
    </p>
    </div>
    <br /><br />
</div>
</template>

<script>
export default {
    name: 'ShopAboutWiderruf'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

</style>
